import type { ComponentType } from 'react'
import type { IPictureElement } from '../../../types/pictures'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'
import { PlaceholderLogo } from '../Icon/TF1Info/PlaceholderLogo'
import { isServer } from '../../../helpers/client'
import { PreloadPictures } from '../PreloadPictures'
import { pictureFullScreenContext } from '../../../contexts/PictureFullScreenContext'
import { theme } from '../../../styles'

const PictureElementListAmp: ComponentType<IPictureElementList> = dynamic(() =>
  import('./PictureElementListAmp').then((module) => module.PictureElementListAmp),
)

export type IPictureElementList = {
  id?: string
  alt?: string
  caption?: string
  elementList?: IPictureElement[]
  importance?: 'low' | 'high' | 'auto'
  fetchPriority?: 'low' | 'high' | 'auto'
  lazyload?: boolean
  placeholderTheme?: 'dark' | 'light'
  placeholderBackgroundTransparent?: boolean
  withoutFallback?: boolean
  withPreload?: boolean
  withFullScreen?: boolean
  layout?:
    | 'responsive'
    | 'fixed'
    | 'fill'
    | 'fixed-height'
    | 'flex-item'
    | 'intrinsic'
    | 'nodisplay'
} & React.HTMLAttributes<HTMLImageElement>

export function PictureElementList(props: IPictureElementList) {
  const isAmp = useAmp()
  return isAmp ? <PictureElementListAmp {...props} /> : <PictureElementListStandard {...props} />
}

export function PictureElementListStandard({
  id,
  alt,
  caption,
  elementList,
  importance,
  fetchPriority,
  lazyload,
  placeholderTheme = 'dark',
  placeholderBackgroundTransparent,
  withoutFallback,
  withPreload,
  withFullScreen = false,
  className,
  ...rest
}: IPictureElementList): JSX.Element {
  const pictureRef = useRef<HTMLPictureElement>(null)
  const imgRef = useRef<HTMLImageElement>(isServer() ? null : document.createElement('img'))
  const [hasError, setHasError] = useState(!elementList?.length)
  const [firstRendered, setfirstRendered] = useState(false)
  const { isFullScreen, setIsFullScreen, setPictureProps } = useContext(pictureFullScreenContext)

  const placeholderBackgroundClassName = placeholderBackgroundTransparent
    ? 'PictureElementList__PlaceHolder_Dark'
    : placeholderTheme === 'dark'
    ? 'PictureElementList__PlaceHolder_Dark'
    : ''

  const handleOnClick =
    !withFullScreen || isFullScreen
      ? () => null
      : () => {
          setPictureProps({
            pictureRef,
            alt,
            caption,
            elementList,
            importance,
            fetchPriority,
            lazyload,
            placeholderTheme,
            placeholderBackgroundTransparent,
            withoutFallback,
            withPreload,
          })
          setIsFullScreen(!isFullScreen)
        }

  useEffect(() => {
    if (hasError) {
      imgRef.current.classList.add('PictureElementList__Error')
    }
  }, [hasError])

  useEffect(() => {
    if (elementList?.length) {
      // Trick to retry to load the image
      // cause onError can be triggered before the js is loaded
      // so the image is broken and we didn't catch the error
      setfirstRendered(true)
    }
  }, [])

  const imageElement = useMemo(
    () => (
      <img
        src={
          elementList?.find((image) => !image.url.includes('avif'))?.url ??
          'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
        }
        alt={alt || caption || ''}
        loading={lazyload ? 'lazy' : 'eager'}
        importance={importance ?? 'low'}
        fetchpriority={fetchPriority ?? 'low'}
        onError={firstRendered ? () => setHasError(true) : null}
        className={hasError ? 'PictureElementList__Error' : ''}
        {...rest}
      />
    ),
    [alt, caption, fetchPriority, firstRendered, hasError, importance, lazyload],
  )

  return (
    <>
      {withPreload ? <PreloadPictures elementList={elementList} /> : null}
      <picture id={id} className={className} ref={pictureRef} onClick={handleOnClick}>
        {elementList?.map((elem, index) => (
          <source
            key={index}
            srcSet={elem.url}
            type={elem?.type}
            media={`(min-width: ${elem.media.min}px) ${
              elem.media.max ? `and (max-width: ${elem.media.max}px)` : ''
            }`}
          />
        ))}
        {imageElement}

        {hasError && !withoutFallback ? (
          <div
            className={[
              'PictureElementList__PlaceHolder flex justify-center items-center',
              placeholderBackgroundClassName,
            ].join(' ')}
          >
            <PlaceholderLogo theme={placeholderTheme} />
          </div>
        ) : null}
      </picture>

      <style jsx>{`
        .PictureElementList__PlaceHolder {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${theme.cssVars.lightBlue};
          z-index: 1;
        }

        .PictureElementList__PlaceHolder_Dark {
          background-color: ${theme.cssVars.deepBlue};
        }

        .PictureElementList__PlaceHolder_Transparent {
          background-color: transparent;
        }

        .PictureElementList__Error {
          visibility: hidden;
          min-height: 150px;
        }
      `}</style>
    </>
  )
}
