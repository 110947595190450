import type { Context, ReactNode } from 'react'
import type { IPictureElementList } from '../components/atoms/PictureElementList'

import { createContext, useState, useRef } from 'react'
import { PictureFullScreen } from '../components/atoms/PictureFullScreen'

export interface IPictureFullScreenContextProps {
  isFullScreen: boolean
  setIsFullScreen: (isFullScreen: boolean) => void
  setPictureProps: (props: { pictureRef?: { current: any } } & IPictureElementList) => void
}

export type IPictureFullScreenContext = Context<IPictureFullScreenContextProps>

export const pictureFullScreenContext: IPictureFullScreenContext = createContext({
  isFullScreen: false,
  setIsFullScreen: () => null,
  setPictureProps: () => null,
})

export function PictureFullScreenContext({ children }: { children?: ReactNode }): JSX.Element {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const pictureRef = useRef<{ pictureRef?: { current: any } } & IPictureElementList>({
    pictureRef: { current: null },
    alt: null,
    caption: null,
    elementList: null,
    importance: null,
    fetchPriority: null,
    lazyload: null,
    placeholderTheme: null,
    placeholderBackgroundTransparent: null,
    withoutFallback: null,
    withPreload: null,
    layout: null,
  })
  const setPictureProps = (props: IPictureElementList) => (pictureRef.current = props)

  return (
    <pictureFullScreenContext.Provider value={{ isFullScreen, setIsFullScreen, setPictureProps }}>
      {isFullScreen && (
        <PictureFullScreen setHasFullScreen={setIsFullScreen} {...pictureRef.current} />
      )}
      {children}
    </pictureFullScreenContext.Provider>
  )
}
